import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

type LogArgs = Parameters<typeof console.log>;

export function verboseLog(...args: LogArgs): void {
    const newError = new Error();
    const stack = newError.stack;
    const stackLines = stack?.split("\n");
    const callerLine = stackLines && stackLines[2] ? stackLines[2] : "";
    const callerOutput = `${callerLine.trim()}

`;

    if (args.length === 0) {
        console.log(callerOutput);
    } else {
        console.log(callerOutput, ...args);
    }
}
