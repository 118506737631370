"use client";

import Hotjar from "@hotjar/browser";
import { useEffect } from "react";
import { verboseLog } from "@/lib/utils";

const productionSiteId = 3887137;
const developmentSiteId = 3588414;
const hotjarVersion = 6;

const HotJarTag = () => {
    useEffect(() => {
        const siteId =
            process.env.NODE_ENV === "production"
                ? productionSiteId
                : developmentSiteId;
        verboseLog("HotJarTag", "init", process.env.NODE_ENV);
        verboseLog("SiteId", siteId);

        Hotjar.init(siteId, hotjarVersion, { debug: true });
    }, []);
    return <></>;
};

export { HotJarTag };
