"use client";
const updateTarget = (target: Element, entry) => {
    const addWhenHidden =
        target.getAttribute("data-animate-when-hidden") === "true";
    const addClass =
        (entry.isIntersecting && !addWhenHidden) ||
        (!entry.isIntersecting && addWhenHidden);

    if (addClass) {
        // Read the data-animate-class attribute of the target
        const animateClass = target.getAttribute("data-animate-class");
        // Add the animateClass to the target's classList
        target.classList.add(animateClass);
    } else {
        // Otherwise remove the animateClass from the target's classList
        const animateClass = target.getAttribute("data-animate-class");
        target.classList.remove(animateClass);
    }
};

const intersectionHandler = (entries) => {
    entries.forEach((entry) => {
        const targetId = entry.target.getAttribute("data-target-id");
        const targets = document.querySelectorAll(
            `[data-trigger-id=${targetId}]`
        );

        targets.forEach((target) => {
            updateTarget(target, entry);
        });
    });
};

const getTriggerMarginVarValue = (triggerMargin) => {
    if (triggerMargin.startsWith("var") || triggerMargin.startsWith("-var")) {
        const negative = triggerMargin.startsWith("-var");
        const variableName = triggerMargin
            .replace("-var", "var")
            .replace("var(", "")
            .replace(")", "");
        const variableValue = getComputedStyle(
            document.documentElement
        ).getPropertyValue(variableName);

        const bareValue = negative ? `-${variableValue}` : variableValue;

        if (bareValue.endsWith("rem")) {
            return (
                parseFloat(
                    getComputedStyle(document.documentElement).fontSize
                ) *
                    parseFloat(bareValue.replace("rem", "")) +
                "px"
            );
        } else {
            return bareValue;
        }
    }

    return null;
};

const setupAnimationListeners = () => {
    // Listen to the items that have targets
    const triggers = document.querySelectorAll("[data-target-id]");

    // collate the boundary ids
    // const bondaries = document.querySelectorAll(
    //     "[data-animation-boundary-ids]"
    // );

    triggers.forEach((trigger) => {
        const triggerMargin = trigger.getAttribute("data-trigger-margin");
        // check to see if a variable is being used
        const triggerMarginVarValue = getTriggerMarginVarValue(triggerMargin);

        const observer = new IntersectionObserver(intersectionHandler, {
            rootMargin: triggerMarginVarValue
                ? triggerMarginVarValue
                : triggerMargin,
            threshold: 0,
        });

        observer.observe(trigger);
    });
};

const Animations = () => {
    // useEffect(() => {
    //     verboseLog("Starting animations");
    //     setupAnimationListeners();
    // }, [usePathname()]);

    return null; //<> </>;
};

export default Animations;
